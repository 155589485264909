import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  marginTop: '5.3rem',
}

export const innerContentSx: SxProps<Theme> = {
  padding: { xs: 0, md: '0 4rem', lg: '0 9.2rem' },
  rowGap: { xs: '4rem', md: '8rem' },
}

export const subtitleSx: SxProps<Theme> = {
  maxWidth: '52.7rem',
}

export const readMoreSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
}

export const founderItemReadMoreSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
  marginLeft: '-0.8rem !important',
  color: (theme) => theme.palette.common.white,
  ':hover': {
    background: 'transparent',
  },
}

export const founderItemQuoteSx: SxProps<Theme> = {
  position: 'absolute',
  top: { xs: '-5rem', lg: '-3.4rem' },
  right: 0,
}

export const emailSx: SxProps<Theme> = {
  color: (theme) => theme.palette.primary.light,
  textDecoration: 'none',
}
