import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/700.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import { FC, PropsWithChildren } from 'react'

import { Breakpoint, CssBaseline } from '@mui/material'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'

export const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 390,
  md: 834,
  lg: 1440,
  xl: 1920,
}

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", Arial, Roboto, source-code-pro',
    htmlFontSize: 10,
    allVariants: {
      color: '#EEEEEE',
    },
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '8.9rem',
      lineHeight: '13.35rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      [`@media (max-width: ${breakpoints.md - 1}px)`]: {
        fontSize: '3.8rem',
        lineHeight: '5.7rem',
      },
    },
    h2: {
      fontSize: '6.4rem',
      lineHeight: '9.6rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      [`@media (max-width: ${breakpoints.md - 1}px)`]: {
        fontSize: '3.8rem',
        lineHeight: '5.7rem',
      },
    },
    h3: {
      fontSize: '3.2rem',
      lineHeight: '4rem',
      fontWeight: 500,
      letterSpacing: '0.015rem',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: '2.4rem',
      letterSpacing: '0.015rem',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '1.8rem',
      lineHeight: '2.6rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      fontWeight: 400,
      letterSpacing: '0.015rem',
    },
    subtitle2: {
      fontSize: '1.4rem',
      lineHeight: '2rem',
      fontWeight: 400,
      letterSpacing: '0.025rem',
    },
    body1: {
      fontFamily: '"Roboto", Arial, Roboto, source-code-pro',
      fontSize: '1.8rem',
      lineHeight: '2.7rem',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Roboto", Arial, Roboto, source-code-pro',
      fontSize: '2.2rem',
      lineHeight: '3.1rem',
      fontWeight: 400,
    },
  },
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    primary: {
      main: '#00A99D',
      dark: '#008076',
      light: '#0CE8D8',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F6AE2D',
      dark: '#D78A00',
      light: '#F1CA83',
    },
    error: {
      main: '#b00020',
      dark: '#790000',
      light: '#e94948',
    },
    warning: {
      main: '#ff9800',
      dark: '#f57c00',
      light: '#ffb74d',
    },
    info: {
      main: '#2196f3',
      dark: '#1976d2',
      light: '#64b5f6',
    },
    grey: {
      '900': '#000000',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
    },
    text: {
      primary: '#EEEEEE',
      secondary: '#525252',
      disabled: '#A2A1A1',
    },
    background: {
      default: '#A2A1A1',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: 0,
          textTransform: 'uppercase',
          boxShadow: 'none',
          '.MuiButton-endIcon > svg': {
            fontSize: '1.6rem',
          },
        },
        outlinedSizeMedium: {
          padding: '0.8rem 2.4rem',
          color: '#A2A1A1',
          border: '1px solid #A2A1A1',
          backgroundColor: '#FFFFFF',
          ':hover': {
            border: '1px solid #A2A1A1',
            backgroundColor: '#EAEAEA',
          },
        },
        containedSizeMedium: {
          padding: '0.8rem 2.4rem',
          color: '#212121',
          backgroundColor: '#9FC0C0',
          ':hover': {
            backgroundColor: '#65A9A9',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontSize: '1.6rem !important',
          lineHeight: '2.4rem',
          fontWeight: 400,
          letterSpacing: '0.015rem',
          color: '#FFFFFF',
          transition: 'color 0.3s ease-in-out',
          ':hover': {
            color: '#9FC0C0',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiFormHelperText-sizeMedium': {
            fontSize: '1.4rem',
            fontWeight: 400,
          },
          '.Mui-error': {
            color: '#e94948 !important',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#212121',
          '.MuiInputBase-root': {
            borderRadius: 0,
            background: '#FFFFFF',
            color: '#000000',
          },
          '.MuiInputLabel-root': {
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: 400,
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
          },
          '.MuiInputBase-input': {
            fontWeight: 400,
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
            '::placeholder': {
              color: 'rgba(0, 0, 0, 0.6)',
              fontWeight: 400,
              fontSize: '1.6rem',
              lineHeight: '2.4rem',
              opacity: 1,
            },
          },
          fieldset: {
            border: 'none',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          background: '#FFFFFF',
          borderRadius: 0,
          color: '#000000',
          fontWeight: 400,
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
        },
        nativeInput: {
          borderRadius: 0,
        },
        select: {
          borderRadius: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#A2A1A1',
          '.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
            borderRadius: 0,
          },
        },
      },
    },
  },
})

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

export { theme }
export default ThemeProvider
