import { Box, Link, Stack, Typography } from '@mui/material'

import ContactForm from '../../../../components/ContactForm'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import { useResponsive } from '../../../../hooks/useResponsive'

import {
  backgroundSx,
  emailSx,
  formWrapperSx,
  leftWrapperSx,
  locationIconStyles,
  wrapperSx,
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
  const { isMobileOrTablet } = useResponsive()

  return (
    <Box sx={backgroundSx}>
      <Stack direction={isMobileOrTablet ? 'column' : 'row'} sx={wrapperSx}>
        <Stack sx={leftWrapperSx} id={HOME_ANCHOR_ID.CONTACT_US}>
          <Box component="img" src="footer-logo.png" maxWidth="fit-content" />
          <Typography>
            We would love to hear from you! Contact us today to explore how our
            consultant services can empower your business. Let's embark on a
            collaborative journey towards your success.
          </Typography>
          <Stack spacing="2.4rem">
            <Stack direction="row" alignItems="center" spacing="1.2rem">
              <FontAwesomeIcon icon={faPhone} color="#9FC0C0" />
              <Typography variant="subtitle1">407.221.5082</Typography>
            </Stack>
            <Stack direction="row" alignItems="baseline" spacing="1.2rem">
              <FontAwesomeIcon
                icon={faLocationDot}
                color="#9FC0C0"
                style={locationIconStyles}
              />
              <Typography variant="subtitle1">
                498 Palm Springs Dr, Suite 270 Altamonte Springs, FL 32701
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing="1.2rem">
              <FontAwesomeIcon icon={faAt} color="#9FC0C0" />
              <Typography variant="subtitle1">
                <Link href="mailto:execs@kapsherconsulting.com" sx={emailSx}>
                  execs@kapsherconsulting.com
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={formWrapperSx}>
          <ContactForm />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Contact
