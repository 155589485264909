import { useState } from 'react'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import ReadMoreDialog, {
  IReadMoreDialogProps,
} from '../../../../components/ReadMoreDialog/ReadMoreDialog'
import DemoCard from '../../../../components/DemoCard'

import {
  emailSx,
  founderItemQuoteSx,
  founderItemReadMoreSx,
  innerContentSx,
  readMoreSx,
  subtitleSx,
  wrapperSx,
} from './styles'
import { IDemoCardProps } from '../../../../components/DemoCard/DemoCard'
import { useResponsive } from '../../../../hooks/useResponsive'

interface IFounderItem {
  photoSrc: string
  name: string
  description: string
  readMore: () => void
}

const FounderItem = ({
  photoSrc,
  name,
  description,
  readMore,
}: IFounderItem) => {
  const { isMobile } = useResponsive()

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing="2.4rem"
      alignItems="flex-start"
      position="relative"
    >
      <Box component="img" src={photoSrc} width="16rem" />
      <Stack spacing="1.6rem">
        <Typography variant="h3">{name}</Typography>
        <Typography fontSize="1.6rem">{description}</Typography>
        <Button
          variant="text"
          onClick={readMore}
          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
          sx={founderItemReadMoreSx}
        >
          Read More
        </Button>
      </Stack>
      <Box component="img" src="quotes.png" sx={founderItemQuoteSx} />
    </Stack>
  )
}

const AboutUs = () => {
  const { isMobile } = useResponsive()

  const initialReadMoreDialogData: IReadMoreDialogProps = {
    open: false,
    title: '',
    paragraphs: [],
    imageSrc: '',
    onClose: () => {},
  }

  const [readMoreDialogData, setReadMoreDialogData] =
    useState<IReadMoreDialogProps>(initialReadMoreDialogData)

  const handleOnResetReadMoreDialogData = () => {
    setReadMoreDialogData((prev) => ({
      ...prev,
      open: false,
    }))

    setTimeout(() => {
      setReadMoreDialogData(initialReadMoreDialogData)
    }, 500)
  }

  const handleOnReadMoreButtonClick = () => {
    setReadMoreDialogData({
      open: true,
      side: 'right',
      title: 'About Us',
      paragraphs: [
        <Typography>
          Kapsher Consulting LLC was created with the premise there must be a
          better way to achieve top-notch results. Our passion for NABIP and our
          local chapters fueled the concept of this company focused on
          supporting associations all over the country. Our strengths come from
          our experience, dedication and loyalty to the insurance industry and
          those who do their best each day to support it.
        </Typography>,
        <Typography>
          Former NAHU Board of Trustees member, Ashley Kapostins has founded
          Kapsher Consulting, LLC. an association management company created to
          take professional trade associations to the next level. Ashley has
          partnered with Dave Sherrill, who has served as Executive Director of
          NABIP Florida since 2006. Ashley and Dave have worked together on
          multiple boards and committees within NABIP and NAIFA for 15 years.
          Kapsher Consulting was born from their joint passion for assisting the
          volunteer leaders of association chapters with building structure,
          preserving history and growing their membership. Ashley and Dave can
          be contacted at{' '}
          <Link href="mailto:execs@kapsherconsulting.com" sx={emailSx}>
            execs@kapsherconsulting.com
          </Link>
          .
        </Typography>,
      ],
      imageSrc: 'about-us.png',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const ABOUT_US_ITEMS: IDemoCardProps[] = [
    {
      title: 'Mission',
      text: `Kapsher Consulting provides comprehensive administrative services that empower your organization to enhance its leadership capacity. Our flexible approach delivers customized solutions that cater to your unique needs, promoting continuity and minimizing disruptions. With our services, your team can focus on strategic goals and your membership, knowing the operational details are in capable hands.`,
      imageSrc: 'mission.png',
    },
    {
      title: 'Vision',
      text: `We strive to be the trusted partner and valued extension of our clients’ teams, delivering exceptional service to drive long-term growth and organizational success.`,
      imageSrc: 'vision.png',
    },
  ]

  const handleOnAshleyKapostinsReadMore = () => {
    setReadMoreDialogData({
      open: true,
      variant: 'secondary',
      title: 'Ashley Kapostins',
      paragraphs: [
        `Ashley Kapostins is Owner and Managing Director of Kapsher Consulting LLC. A graduate of the University of Central Florida, where she earned a Bachelor’s of Science degree in Business Management.`,
        `Ashley started in the insurance industry in 2007. Her career has focused on the individual and small group markets, and she has worked in the retail side as well as with insurance carriers in both segments. She last served as a National Sales Manager for Cigna Individual and Family Plans, managing the broker manager sales team.`,
        `Ashley has been an active member of the National Association of Benefits and Insurance Professionals since 2007. She has served in numerous leadership roles at the local, state and national level, including as President of the Central Florida and Florida State chapters. She has served on the national Board of Trustees as chair of the Professional Development Council and the Membership Council, focusing on communicating and building upon the value of the association’s membership. She was the first Vice-Chair of the New Agents Advisory Group, which has evolved into today’s Vanguard Council.  She is a 5-time Triple Crown recipient and has also served on the national finance committee. Currently she sits on the NABIP Foundation Board of Directors.`,
        `Ashley is the proud mother of two boys and one girl (Kai, Ryder and Quinn). Her husband, Jake is a software engineer and entrepreneur. She is an avid Georgia Bulldog fan, and food is her love language.`,
      ],
      imageSrc: 'ashley.png',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const handleOnDaveSherrillReadMore = () => {
    setReadMoreDialogData({
      open: true,
      variant: 'secondary',
      title: 'Dave Sherrill',
      paragraphs: [
        `Dave started in the insurance industry in 1993 in an administrative support role doing quotes for individual and group major medical plans in his father’s brokerage. The brokerage and Dave’s responsibilities evolved through the years into offering Annuities, Life Insurance, Long Term Care, Disability Income, Medicare Supplements, Dental Insurance and Medicare Advantage plans. Dave bought the brokerage in April 2016 and is now President & CEO of Sherrill Insurance Brokerage, Inc.`,
        `Dave joined the Central Florida Association of Health Underwriters in March of 1995 and became a part of the board as Legislative Chair in 1997. He served as CFAHU President in 2001-2002. Dave has participated with the Florida AHU board since 1998, where he served as Newsletter Chair and Membership Chair, while he moved through the chairs and served as President for the 2003-2004 board year. Dave has sponsored nearly 200 new NAHU/NABIP members during his years of involvement and he has qualified for NAHU’s Triple Crown Award every year since its inception in 2004.`,
        `In 2007, he stepped back in to serve as President of the Central Florida chapter when there was a sudden need. He served as Treasurer for a few years after that. He currently serves as the Executive Director for NABIP Florida (since 2006) and NABIP Central Florida (since 2011) and serves as NABIP Regional Vice President for Region 5.`,
        `Dave has also been a member of the NAIFA Central Florida chapter since 1994 and served on their board of directors from 2006-2017. In 2023 he co-founded Kapsher Consulting, LLC an Association Management Company. Dave has been nominated to serve as NABIP Regional Vice President for 2023-2025.`,
        `Dave can be contacted at his office at 407-831-5000, his cell at 407-221-5082 or dave@kapsherconsulting.com.`,
      ],
      imageSrc: 'dave.png',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const FOUNDER_ITEMS: IFounderItem[] = [
    {
      photoSrc: 'ashley.png',
      name: 'Ashley Kapostins',
      description: `Ashley Kapostins is Owner and Managing Director of Kapsher Consulting LLC. A graduate of the University of Central Florida, where she earned a Bachelor’s of Science degree in Business Management...`,
      readMore: handleOnAshleyKapostinsReadMore,
    },
    {
      photoSrc: 'dave.png',
      name: 'Dave Sherrill',
      description: `Dave started in the insurance industry in 1993 in an administrative support role doing quotes for individual and group major medical plans in his father’s brokerage. The brokerage and Dave’s...`,
      readMore: handleOnDaveSherrillReadMore,
    },
  ]

  return (
    <>
      <ReadMoreDialog {...readMoreDialogData} />

      <Stack sx={wrapperSx} id={HOME_ANCHOR_ID.ABOUT_US}>
        <Typography variant="h1">About Us</Typography>
        <Stack sx={innerContentSx}>
          <Stack spacing="2.4rem">
            <Typography variant="body2" sx={subtitleSx}>
              Kapsher Consulting LLC was created with the premise there must be
              a better way to achieve top-notch results. Our passion for NABIP
              and our local chapters fueled the concept of this company focused
              on supporting associations all over the country.
            </Typography>
            <Button
              variant="outlined"
              endIcon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={handleOnReadMoreButtonClick}
              sx={readMoreSx}
            >
              Read More
            </Button>
          </Stack>
          {ABOUT_US_ITEMS.map((item, index) => (
            <DemoCard
              key={index}
              {...item}
              variant={index % 2 === 0 || isMobile ? 'right' : 'left'}
            />
          ))}
          <Stack spacing="5.6rem">
            <Typography variant="h2">Meet the Founders</Typography>
            {FOUNDER_ITEMS.map((item, index) => (
              <FounderItem key={index} {...item} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default AboutUs
