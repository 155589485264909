import { Box, Button, Typography } from '@mui/material'

import NavigationItem from '../NavigationItem'

import { HOME_ANCHOR_ID } from '../../../../utils/types'
import { scrollToElement } from '../../../../utils/scroll'

import { contactUsButtonSx, copyrightSx, navSx, wrapperSx } from './styles'

interface IExpandedMenuProps {
  activeSection: HOME_ANCHOR_ID
  onNavigationItemClicked?: () => void
}

const ExpandedMenu = ({
  activeSection,
  onNavigationItemClicked,
}: IExpandedMenuProps) => {
  const handleOnContactUsButtonClick = (event: React.MouseEvent) => {
    onNavigationItemClicked?.()
    scrollToElement(event, HOME_ANCHOR_ID.CONTACT_US)
  }

  return (
    <Box sx={wrapperSx}>
      <Box
        component="nav"
        display="flex"
        gap="2.4rem"
        flex="1"
        justifyContent="flex-end"
        sx={navSx}
      >
        <NavigationItem
          aria-label="Top"
          label="Top"
          elementId={HOME_ANCHOR_ID.TOP}
          isSelected={activeSection === HOME_ANCHOR_ID.TOP}
          onClick={() => onNavigationItemClicked?.()}
        />
        <NavigationItem
          aria-label="Services"
          label="Services"
          elementId={HOME_ANCHOR_ID.SERVICES}
          isSelected={activeSection === HOME_ANCHOR_ID.SERVICES}
          onClick={() => onNavigationItemClicked?.()}
        />
        <NavigationItem
          aria-label="What It Is"
          label="What It Is"
          elementId={HOME_ANCHOR_ID.WHAT_IT_IS}
          isSelected={activeSection === HOME_ANCHOR_ID.WHAT_IT_IS}
          onClick={() => onNavigationItemClicked?.()}
        />
        <NavigationItem
          aria-label="About Us"
          label="About Us"
          elementId={HOME_ANCHOR_ID.ABOUT_US}
          isSelected={activeSection === HOME_ANCHOR_ID.ABOUT_US}
          onClick={() => onNavigationItemClicked?.()}
        />
      </Box>
      <Button
        aria-label="Contact Us"
        variant="contained"
        size="medium"
        sx={contactUsButtonSx}
        onClick={handleOnContactUsButtonClick}
      >
        Contact Us
      </Button>
      <Typography sx={copyrightSx}>©Kapsher Consulting LLC</Typography>
    </Box>
  )
}

export default ExpandedMenu
