import { Box } from '@mui/material'

import { scrollToTop } from '../../utils/scroll'

interface ILogoProps {
  lightMode?: boolean
}

const Logo = ({ lightMode }: ILogoProps) => {
  const handleOnLogoClick = () => {
    scrollToTop()
  }

  const getLogoSrc = () => {
    if (lightMode) {
      return 'logo-light.png'
    }

    return 'logo.png'
  }

  return (
    <Box
      component="img"
      src={getLogoSrc()}
      onClick={handleOnLogoClick}
      sx={{ maxWidth: '100%' }}
    />
  )
}

export default Logo
