import { useState } from 'react'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'

import DemoCard from '../../../../components/DemoCard'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import { IDemoCardProps } from '../../../../components/DemoCard/DemoCard'
import ReadMoreDialog, {
  IReadMoreDialogProps,
} from '../../../../components/ReadMoreDialog/ReadMoreDialog'

import {
  demoCardSx,
  innerContentSx,
  readMoreSx,
  subtitleSx,
  wrapperSx,
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

interface IOurProcessItem {
  iconSrc: string
  title: string
  description: string
  readMore: () => void
}

const OurProcessItem = ({
  iconSrc,
  title,
  description,
  readMore,
}: IOurProcessItem) => {
  return (
    <Stack direction="row" spacing="1.2rem" alignItems="flex-start">
      <Box component="img" src={iconSrc} width="6rem" />
      <Stack spacing="1.2rem">
        <Typography variant="h4">{title}</Typography>
        <Typography fontSize="1.6rem" pb="1.2rem">
          {description}
        </Typography>
        <Button
          variant="text"
          onClick={readMore}
          endIcon={<FontAwesomeIcon icon={faArrowRight} />}
          sx={readMoreSx}
        >
          Read More
        </Button>
      </Stack>
    </Stack>
  )
}

const WhatItIs = () => {
  const initialReadMoreDialogData: IReadMoreDialogProps = {
    open: false,
    title: '',
    paragraphs: [],
    imageSrc: '',
    onClose: () => {},
  }

  const [readMoreDialogData, setReadMoreDialogData] =
    useState<IReadMoreDialogProps>(initialReadMoreDialogData)

  const handleOnResetReadMoreDialogData = () => {
    setReadMoreDialogData((prev) => ({
      ...prev,
      open: false,
    }))

    setTimeout(() => {
      setReadMoreDialogData(initialReadMoreDialogData)
    }, 500)
  }

  const handleOnWhatIsAssociationManagementReadMore = () => {
    setReadMoreDialogData({
      open: true,
      side: 'left',
      title: 'What is association management?',
      paragraphs: [
        `Association management is a professional service that helps organizations run their operations and manage their affairs. These organizations are typically professional associations, trade organizations and other types of membership-based groups.`,
        `Association management services include a wide range of administrative, financial and operational support, such as membership management, event planning, budgeting, financial reporting and communications.`,
        `In addition to comprehensive full-service solutions, we also offer standalone and short-term services, such as meeting planning and website development.`,
        `Kapsher Consulting can also provide consulting services for projects, leadership succession planning and strategic and budget planning.`,
        `Let us take care of the operational details so you can focus on growing and advancing your organization.`,
      ],
      imageSrc: 'what-is-association-management-dialog.png',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const handleOnHowItWorksReadMore = () => {
    setReadMoreDialogData({
      open: true,
      side: 'right',
      title: 'How It Works?',
      paragraphs: [
        `By partnering with an Association Management Company, you can delegate your association’s day to day operations and administrative tasks to experienced professionals. This relationship can help streamline the process across all aspects of your associations, freeing up your time to focus on strategic priorities and membership value.`,
        `In addition to comprehensive full-service solutions, we also offer standalone and short-term services, such as meeting planning and website development.`,
        `Kapsher Consulting can also provide consulting services for projects, leadership succession planning and strategic and budget planning.`,
        `Let us take care of the operational details so you can focus on growing and advancing your organization.`,
      ],
      imageSrc: 'how-it-works-dialog.png',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const handleOnDiscoveryReadMore = () => {
    setReadMoreDialogData({
      open: true,
      variant: 'secondary',
      title: '1. DISCOVERY',
      paragraphs: [
        `Our process starts when you submit a Request for Proposal (RFP) or reach out to us for a consultation, where we will promptly reach out to schedule a discovery call. During this call we will go through your current goals, needs and any headaches. Our proposal that we will return to your team will outline our available services, scope of work and contract terms. We feel confident that we can work together to create the right balance of services and resources to help you and your team achieve maximum success.`,
      ],
      imageSrc: 'discovery-dialog-icon.svg',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const handleOnTransitioningReadMore = () => {
    setReadMoreDialogData({
      open: true,
      variant: 'secondary',
      title: '2. TRANSITIONING INTO OUR NEW RELATIONSHIP',
      paragraphs: [
        `Once we identify the perfect proposal solution for your organization, we will begin our transition process. We will work closely with your leadership team and if applicable, your previous management company (or individual) to ensure a seamless transition into your new contract. This process could include migrating tools, updating membership or website materials and other various logistical information. The timeline of the transition phase can vary by needs and current situation.`,
      ],
      imageSrc: 'transitioning-dialog-icon.svg',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const handleOnTheDayToDayReadMore = () => {
    setReadMoreDialogData({
      open: true,
      variant: 'secondary',
      title: '3. THE DAY-TO-DAY',
      paragraphs: [
        `Once the transition process is complete, our day-to-day relationship begins. Your organization will always have a team dedicated to achieving the goals of our contract. This could include an executive director or any specialists specific to the services you have chosen, such as website designers or event coordinators. `,
        `We guarantee to be in close contact via any preferred medium of communication and encourage transparent, consistent communication with your team to ensure we are delivering on our contract specifics.`,
        `At this part of the process, your job is to sit back and enjoy your new support system. We strive to be the trusted partner and valued extension of our clients’ teams, delivering exceptional service to drive long-term growth and organizational success.`,
      ],
      imageSrc: 'day-to-day-dialog-icon.svg',
      onClose: handleOnResetReadMoreDialogData,
    })
  }

  const WHAT_IT_IS_ITEMS: IDemoCardProps[] = [
    {
      title: 'What is association management?',
      text: `Association management is a professional service that helps organizations run their operations and manage their affairs. These organizations are typically professional associations, trade organizations and other types of membership-based groups...`,
      imageSrc: 'what-is-association-management.png',
      readMore: handleOnWhatIsAssociationManagementReadMore,
    },
    {
      title: 'How It Works?',
      text: `By partnering with an Association Management Company, you can delegate your association’s day to day operations and administrative tasks to experienced professionals. This relationship can help streamline the process across all aspects of your...`,
      imageSrc: 'how-it-works.png',
      readMore: handleOnHowItWorksReadMore,
    },
  ]

  const OUR_PROCESS_ITEMS: IOurProcessItem[] = [
    {
      iconSrc: 'discovery-icon.svg',
      title: 'DISCOVERY',
      description:
        'Uncovering insights, shaping possibilities. Unlocking potential through exploration.',
      readMore: handleOnDiscoveryReadMore,
    },
    {
      iconSrc: 'transitioning-icon.svg',
      title: 'TRANSITIONING',
      description:
        'Embracing a new chapter together, forging a path of growth and shared success.',
      readMore: handleOnTransitioningReadMore,
    },
    {
      iconSrc: 'day-to-day-icon.svg',
      title: 'THE DAY-TO-DAY',
      description: 'Efficiently managing operations, optimizing productivity.',
      readMore: handleOnTheDayToDayReadMore,
    },
  ]

  return (
    <>
      <ReadMoreDialog {...readMoreDialogData} />

      <Stack sx={wrapperSx} id={HOME_ANCHOR_ID.WHAT_IT_IS}>
        <Typography variant="h1">What It Is</Typography>
        <Stack sx={innerContentSx}>
          <Typography variant="body2" sx={subtitleSx}>
            By partnering with Kapsher Consulting, you can delegate your
            association’s day to day operations and administrative tasks to
            experienced professionals. This relationship can help streamline the
            process across all aspects of your association, freeing up your time
            to focus on strategic priorities and membership value.
          </Typography>
          {WHAT_IT_IS_ITEMS.map(({ title, text, imageSrc, readMore }, i) => {
            const variant = i % 2 === 0 ? 'left' : 'right'
            return (
              <DemoCard
                key={title}
                title={title}
                text={text}
                imageSrc={imageSrc}
                variant={variant}
                readMore={readMore}
                sx={demoCardSx}
              />
            )
          })}
          <Stack>
            <Typography variant="h2" mb="4rem">
              Our Process
            </Typography>
            <Grid container rowSpacing="2.4rem" columnSpacing="2.4rem">
              {OUR_PROCESS_ITEMS.map(
                ({ iconSrc, title, description, readMore }) => (
                  <Grid item xs={12} lg={4}>
                    <OurProcessItem
                      key={title}
                      iconSrc={iconSrc}
                      title={title}
                      description={description}
                      readMore={readMore}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default WhatItIs
