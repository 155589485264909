import { SxProps, Theme } from '@mui/material'
import { CSSProperties } from 'react'

export const backgroundSx: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#575757',
  width: 'calc(100% - 5.4rem)',
  marginLeft: '5.4rem',
  marginTop: { xs: '2.4rem', lg: '7.3rem' },
  minHeight: { lg: '66.1rem' },
  paddingLeft: { xs: '2.4rem', lg: '15.8rem', xl: 0, },
  paddingRight: { xs: '2.4rem', lg: 0 },
}

export const wrapperSx: SxProps<Theme> = {
  gap: { lg: '4rem' },
  justifyContent: 'space-between',
  flexDirection: { xs: 'column-reverse', lg: 'row' },
  width: { xl: '156.7rem' },
  padding: { lg: '0 4.5rem' },
}

export const leftWrapperSx: SxProps<Theme> = {
  maxWidth: { lg: '34.3rem' },
  rowGap: '3.2rem',
  padding: { xs: '2.4rem', lg: '4rem 0' },
}

export const formWrapperSx: SxProps<Theme> = {
  position: 'relative',
  padding: { xs: '2.4rem', lg: '4rem 0' },
  width: '100%',
  alignItems: 'center',
}

export const emailSx: SxProps<Theme> = {
  color: (theme) => theme.palette.primary.light,
  textDecoration: 'none',
}

export const locationIconStyles: CSSProperties = {
  position: 'relative',
  top: '0.3rem',
}
