import { useRef } from 'react'

import { Box, Drawer, IconButton, useTheme } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'

import ExpandedMenu from '../ExpandedMenu'

import Logo from '../../../Logo'

import { useMenu } from '../../../../hooks/useMenu'
import { useResponsive } from '../../../../hooks/useResponsive'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import {
  closeIconStyles,
  closeIconWrapperSx,
  drawerModalSx,
  drawerPaperSx,
  iconButtonStyles,
  leftMenuBarSx,
  logoWrapperSx,
} from './styles'

interface ICollapsedMenuProps {
  activeSection: HOME_ANCHOR_ID
}

const CollapsedMenu = ({ activeSection }: ICollapsedMenuProps) => {
  const theme = useTheme()
  const { isMobileOrTablet, isDesktopOrBigger } = useResponsive()
  const { isMenuOpen, onMenuClick, onMenuClose } = useMenu()

  const menuButonRef = useRef<HTMLButtonElement>(null)

  const handleOnNavigationItemClicked = () => {
    if (!isMobileOrTablet) {
      return
    }

    onMenuClose()
  }

  return (
    <>
      <Box sx={leftMenuBarSx}>
        <IconButton
          id="menu-button"
          aria-label="Fullscreen menu button"
          ref={menuButonRef}
          aria-haspopup="true"
          aria-controls={isMenuOpen ? 'menu' : undefined}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={onMenuClick}
        >
          <FontAwesomeIcon icon={faBars} style={iconButtonStyles(theme)} />
        </IconButton>
        <Box component="img" src="sidebar-small-logo.png" />
      </Box>
      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={onMenuClose}
        disableEnforceFocus
        PaperProps={{ sx: drawerPaperSx }}
        hideBackdrop={isDesktopOrBigger}
        disableScrollLock={isDesktopOrBigger}
        ModalProps={{
          disablePortal: isDesktopOrBigger,
          sx: drawerModalSx,
        }}
      >
        <Box sx={closeIconWrapperSx} onClick={onMenuClose}>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            style={closeIconStyles(theme)}
          />
        </Box>
        <Box sx={logoWrapperSx}>
          <Logo lightMode />
        </Box>
        <ExpandedMenu
          activeSection={activeSection}
          onNavigationItemClicked={handleOnNavigationItemClicked}
        />
      </Drawer>
    </>
  )
}

export default CollapsedMenu
