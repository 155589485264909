import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import AppProvider from './contexts'

import Routes from './routes/index.routes'

function App() {
  return (
    <Suspense fallback="">
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
