import { Route, Routes as RouterRoutes } from 'react-router-dom'

import ScrollToTop from '../components/ScrollToTop'

import Home from '../pages/Home'

const Routes = () => {
  return (
    <>
      <ScrollToTop />
      <RouterRoutes>
        <Route path="/" element={<Home />} />
      </RouterRoutes>
    </>
  )
}

export default Routes
