import { SxProps, Theme } from '@mui/material'

export const mainSx: SxProps<Theme> = { overflow: 'hidden' }

export const parentWrapperSx: SxProps<Theme> = {
  marginLeft: { xs: '5.5rem', lg: '21.2rem' },
}

export const containerSx: SxProps<Theme> = {
  position: 'relative',
  maxWidth: {
    xs: '100%',
    lg: '121rem',
    xl: '154.8rem',
  },
}

export const wrapperSx: SxProps<Theme> = {
  marginTop: { xs: '4rem', lg: '8rem' },
}
