import { SxProps, Theme } from '@mui/material'
import { CSSProperties } from 'react'

export const iconWrapperSx = ({
  isVisible,
}: {
  isVisible: boolean
}): SxProps<Theme> => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  right: { xs: '1.2rem', lg: '2.4rem' },
  bottom: { xs: '1.2rem', lg: '2.4rem' },
  left: { xs: 'calc(100% - 4.2rem)', lg: 'calc(100% - 6.4rem)' },
  cursor: 'pointer',
  zIndex: 1000,
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  background: '#9FC0C0',
  width: '3rem',
  height: '3rem',

  ...(isVisible && {
    opacity: 1,
  }),
})

export const iconSx = (theme: Theme): CSSProperties => ({
  color: theme.palette.common.white,
})
