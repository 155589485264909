import { useCallback } from 'react'
import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { copyWrapperSx, imageSx, readMoreSx, wrapperSx } from './styles'
import { useResponsive } from '../../hooks/useResponsive'

export interface IDemoCardProps {
  title: string
  text: string
  imageSrc: string
  variant?: 'left' | 'right'
  readMore?: () => void
  sx?: SxProps<Theme>
}

const DemoCard = ({
  title,
  text,
  imageSrc,
  variant = 'right',
  readMore,
  sx: restSx,
  ...rest
}: IDemoCardProps) => {
  const { isMobile } = useResponsive()

  const renderImage = useCallback(
    () => <Box sx={imageSx({ imageSrc })} />,
    [imageSrc]
  )

  return (
    <Stack rowGap={2} {...rest}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        sx={{ ...wrapperSx, ...restSx } as SxProps<Theme>}
      >
        {variant === 'left' && renderImage()}
        <Stack sx={copyWrapperSx}>
          <Typography variant="h3">{title}</Typography>
          <Typography whiteSpace="pre-line">{text}</Typography>
          {readMore && (
            <Button
              variant="outlined"
              endIcon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={readMore}
              sx={readMoreSx}
            >
              Read More
            </Button>
          )}
        </Stack>
        {variant === 'right' && renderImage()}
      </Stack>
    </Stack>
  )
}

export default DemoCard
