import { AVAILABLE_ASSISTANCE_SERVICES, IItem } from './types'

export const AVAILABLE_ASSISTANCE_SERVICES_ITEMS: IItem[] = [
  {
    value: AVAILABLE_ASSISTANCE_SERVICES.FULL_SERVICE_MANAGEMENT,
    label: 'Full Service Management',
  },
  {
    value: AVAILABLE_ASSISTANCE_SERVICES.EXECUTIVE_DIRECTOR_SERVICES,
    label: 'Executive Director Services',
  },
  {
    value: AVAILABLE_ASSISTANCE_SERVICES.MEETING_AND_EVENT_MANAGEMENT,
    label: 'Meeting and Event Management',
  },
  {
    value: AVAILABLE_ASSISTANCE_SERVICES.VIRTUAL_EVENT_MANAGEMENT,
    label: 'Virtual Event Management',
  },
  {
    value: AVAILABLE_ASSISTANCE_SERVICES.COMMUNICATIONS,
    label: 'Communications',
  },
  {
    value: AVAILABLE_ASSISTANCE_SERVICES.WEBSITE_ASSISTANCE,
    label: 'Website Assistance',
  },
  {
    value: AVAILABLE_ASSISTANCE_SERVICES.OTHER,
    label: 'Other',
  },
]

export interface IContactForm {
  firstName: string
  lastName: string
  email: string
  phone: string
  organization: string
  role: string
  website: string
  services: AVAILABLE_ASSISTANCE_SERVICES[]
  currentManagement: string
  additionalInformation: string
  rfpFile?: File
  additionalFiles?: File[]
}
