import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  alignItems: 'center',
  gap: '2.4rem',
}

export const copyWrapperSx: SxProps<Theme> = {
  gap: '1.6rem',
  width: { md: '31.4rem', lg: '43.6rem', xl: '64.8rem' },
  minWidth: { md: '31.4rem', lg: '43.6rem', xl: '64.8rem' },
}

export const readMoreSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
}

export const imageSx = ({
  imageSrc,
}: {
  imageSrc: string
}): SxProps<Theme> => ({
  width: '100%',
  maxWidth: { xl: '64.8rem' },
  height: '31.4rem',
  maxHeight: '31.4rem',
  background: `url(${imageSrc}) no-repeat center center`,
  backgroundSize: 'cover',
  boxShadow: '0rem 0.4rem 2.0rem rgba(0, 0, 0, 0.25)',
})
