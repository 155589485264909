import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  position: 'relative',
}

const SHARED_POLIGON_STYLES: SxProps<Theme> = {
  position: 'absolute',
  width: { md: '50%', lg: 'fit-content' },
  height: 'fit-content',
  zIndex: '-1',
  overflow: 'hidden',
  opacity: '0.5',
}

export const poligon1Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: '0',
  left: '0',
}

export const poligon2Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '22.4rem', lg: '45.8rem' },
  right: '-35.65rem',
}

export const poligon3Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '130rem', lg: '105rem' },
  left: { md: '-25rem', lg: '-50rem' },
  transform: 'rotate(90deg)',
}

export const poligon4Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '158rem', lg: '145rem' },
  right: { md: '-32rem', lg: '-48rem' },
  transform: 'rotate(90deg)',
}

export const poligon5Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '210rem', lg: '208rem' },
  left: { md: '-20rem', lg: '-40rem' },
  transform: { md: 'none', lg: 'rotate(90deg)' },
}

export const poligon6Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '245rem', lg: '248rem' },
  right: { md: '-30rem', lg: '-45rem' },
  transform: 'rotate(90deg)',
}

export const poligon7Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '323rem', lg: '315rem' },
  left: { md: 'unset', lg: '-40rem' },
  right: { md: '-33rem', lg: 'unset' },
}

export const poligon8Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '358rem', lg: '352rem' },
  left: { md: '-24.4rem', lg: 'unset' },
  right: { md: 'unset', lg: '-28rem' },
  transform: 'none',
}

export const poligon9Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '411.5rem', lg: '388rem' },
  left: { md: '-24.8rem', lg: '-46rem' },
  transform: 'rotate(90deg)',
}

export const poligon10Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '428rem', lg: '443rem' },
  right: { md: '-22rem', lg: '-9rem' },
}

export const poligon11Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '411.5rem', lg: '468rem' },
  left: { md: '-24.8rem', lg: '-40rem' },
  transform: 'rotate(90deg)',
}

export const poligon12Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '460.8rem', lg: '563.5rem' },
  left: { md: '-29rem', lg: '-50rem' },
  transform: { md: 'rotate(90deg)', lg: 'none' },
}

export const poligon13Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '550rem', lg: '645rem' },
  left: { md: 'unset', lg: '-40rem' },
  right: { md: '-23rem', lg: 'unset' },
  transform: { md: 'none', lg: 'rotate(90deg)' },
}

export const poligon14Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: { md: '560rem', lg: '673rem' },
  right: { md: 'unset', lg: '-48rem' },
  left: { md: '-35rem', lg: 'unset' },
  transform: { md: 'none', lg: 'rotate(90deg)' },
}

export const poligon15Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: '625rem',
  right: '-32rem',
  transform: 'rotate(90deg)',
}

export const poligon16Sx: SxProps<Theme> = {
  ...SHARED_POLIGON_STYLES,
  top: '625rem',
  left: '-32rem',
  transform: 'rotate(90deg)',
}
