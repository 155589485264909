import axios from 'axios'
import AWSService from './aws'

import { IContactForm } from '../utils/contact-form'

const sendContactForm = async (data: IContactForm): Promise<any> => {
  try {
    const { rfpFile, additionalFiles, ...rest } = data

    let rfpFileDocument
    if (!!rfpFile) {
      const response = await AWSService.uploadFile({
        file: rfpFile,
        referenceId: rest.email,
        folder: 'rfp',
      })

      rfpFileDocument = {
        name: rfpFile.name,
        url: response?.Location,
      }
    }

    let additionalFilesDocuments: any[] = []
    if (!!additionalFiles?.length) {
      additionalFilesDocuments = await Promise.all(
        additionalFiles.map(async (file) => {
          const response = await AWSService.uploadFile({
            file,
            referenceId: rest.email,
            folder: 'additional',
          })

          return {
            name: file.name,
            url: response?.Location,
          }
        })
      )
    }

    const response = await axios.post(
      '/contact/submitform',
      {
        ...rest,
        rfpFile: rfpFileDocument,
        additionalFiles: additionalFilesDocuments,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )

    return response
  } catch (err) {
    console.log(err)
  }
}

const exports = {
  sendContactForm,
}

export default exports
