import { SxProps, Theme } from '@mui/material'

export const navigationItemSx = ({
  isSelected,
}: {
  isSelected: boolean
}): SxProps<Theme> => ({
  cursor: 'pointer',
  paddingY: '1rem',
  color: (theme) => theme.palette.primary.contrastText,
  textDecoration: 'none',
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
  letterSpacing: '0.125rem',
  display: 'flex',
  alignItems: 'center',

  ...(isSelected && {
    color: (theme) => theme.palette.primary.main,
  }),
})
