export interface IItem {
  value: string,
  label: string,
}

export enum HOME_ANCHOR_ID {
  TOP = 'top',
  SERVICES = 'services',
  WHAT_IT_IS = 'what-is-this',
  ABOUT_US = 'about-us',
  CONTACT_US = 'contact-us',
}

export enum AVAILABLE_ASSISTANCE_SERVICES {
  FULL_SERVICE_MANAGEMENT = 'Full Service Management',
  EXECUTIVE_DIRECTOR_SERVICES = 'Executive Director Services',
  MEETING_AND_EVENT_MANAGEMENT = 'Meeting and Event Management',
  VIRTUAL_EVENT_MANAGEMENT = 'Virtual Event Management',
  COMMUNICATIONS = 'Communications',
  WEBSITE_ASSISTANCE = 'Website Assistance',
  OTHER = 'Other',
}

export const ALLOWED_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
]