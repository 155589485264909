import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  marginTop: '5.3rem',
  marginBottom: { xs: '4rem', lg: '12rem' },
}

export const innerContentSx: SxProps<Theme> = {
  padding: { xs: 0, md: '0 4rem', lg: '0 9.2rem' },
  rowGap: { xs: '4rem', md: '8rem' },
}

export const subtitleSx: SxProps<Theme> = {
  maxWidth: '52.7rem',
}

export const demoCardSx: SxProps<Theme> = {
  alignItems: 'flex-start',
}

export const readMoreSx: SxProps<Theme> = {
  alignSelf: 'flex-start',
  marginLeft: '-0.8rem !important',
  marginTop: 'auto !important',
  color: (theme) => theme.palette.common.white,
  ':hover': {
    background: 'transparent',
  },
}
