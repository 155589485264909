import { Stack, Typography } from '@mui/material'

import { wrapperSx } from './styles'
import { useResponsive } from '../../hooks/useResponsive'

const CopyrightFooter = () => {
  const { isTabletOrBigger } = useResponsive()

  return (
    <Stack direction={isTabletOrBigger ? 'row' : 'column'} sx={wrapperSx}>
      <Typography variant="subtitle2">©Kapsher Consulting LLC</Typography>
      {isTabletOrBigger && <Typography variant="subtitle2">|</Typography>}
      <Typography variant="subtitle2">All Rights Reserved</Typography>
    </Stack>
  )
}

export default CopyrightFooter
