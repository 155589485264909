import { useEffect, useState } from 'react'

import CollapsedMenu from './components/CollapsedMenu'

import { HOME_ANCHOR_ID } from '../../utils/types'

const Navbar = () => {
  const [activeSection, setActiveSection] = useState(HOME_ANCHOR_ID.TOP)

  const handleScroll = () => {
    const sectionIds = Object.values(HOME_ANCHOR_ID)
    let activeSectionId = ''

    sectionIds.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        const { top } = element.getBoundingClientRect()
        const sectionTop = top + window.scrollY

        if (
          window.scrollY + window.innerHeight ===
          document.documentElement.scrollHeight
        ) {
          activeSectionId = sectionIds[sectionIds.length - 1]
        } else if (window.scrollY >= sectionTop - 246.5) {
          activeSectionId = id
        }
      }
    })

    setActiveSection(activeSectionId as HOME_ANCHOR_ID)

    if (activeSectionId) {
      const href = `#${activeSectionId}`
      if (window.location.hash !== href) {
        window.history.replaceState(null, '', href)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    if (window.location.hash) {
      setTimeout(() => {
        window.removeEventListener('scroll', handleScroll)

        const hash = window.location.hash.substring(1)
        const element = document.getElementById(hash)

        if (element) {
          const yCoordinate =
            element.getBoundingClientRect().top + window.scrollY
          const yOffset = -10
          setActiveSection(hash as HOME_ANCHOR_ID)
          window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
        }

        setTimeout(() => {
          window.addEventListener('scroll', handleScroll)
        }, 1000)
      }, 500)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return <CollapsedMenu activeSection={activeSection} />
}

export default Navbar
