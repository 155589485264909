import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '1.6rem',
  alignItems: 'center',
  justifyContent: 'center',
}

export const navSx: SxProps<Theme> = {
  alignItems: 'center',
  flexDirection: 'column',
  flex: 0,
}

export const contactUsButtonSx: SxProps<Theme> = {
  marginLeft: 0,
  marginTop: '2.4rem',
  width: 'fit-content',
}

export const copyrightSx: SxProps<Theme> = {
  marginTop: '8rem',
  fontSize: '1.4rem',
  lineHeight: '2rem',
  letterSpacing: '0.025rem',
  color: '#BDBDBD',
}
