import { useCallback } from 'react'
import { Backdrop, Box, Fade, Modal, Stack, Typography } from '@mui/material'

import { useResponsive } from '../../hooks/useResponsive'

import {
  closeIconWrapperSx,
  imageSx,
  innerWrapperSx,
  secondaryImageSx,
  modalSx,
  wrapperSx,
  contentSx,
} from './styles'

export interface IReadMoreDialogProps {
  open: boolean
  title: string
  paragraphs: string[] | JSX.Element[]
  imageSrc: string
  onClose: () => void
  variant?: 'default' | 'secondary'
  side?: 'left' | 'right'
}

const ReadMoreDialog = ({
  open,
  title,
  paragraphs,
  imageSrc,
  onClose,
  variant = 'default',
  side = 'left',
}: IReadMoreDialogProps) => {
  const { isMobile } = useResponsive()

  const renderDefaultVariant = useCallback(() => {
    return (
      <>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing="2.4rem"
          mb="2rem"
        >
          {side === 'left' && <Box sx={imageSx({ imageSrc })} />}
          <Stack spacing="1.6rem">
            <Typography variant="h3">{title}</Typography>
            <Typography>{paragraphs[0]}</Typography>
          </Stack>
          {side === 'right' && <Box sx={imageSx({ imageSrc })} />}
        </Stack>
        <Stack spacing="1.2rem">
          {paragraphs.slice(1).map((paragraph, index) => {
            if (typeof paragraph === 'string') {
              return <Typography key={index}>{paragraph}</Typography>
            }

            return paragraph
          })}
        </Stack>
      </>
    )
  }, [imageSrc, isMobile, paragraphs, side, title])

  const renderSecondaryVariant = useCallback(() => {
    return (
      <Stack direction={isMobile ? 'column' : 'row'} spacing="2.2rem">
        <Box component="img" src={imageSrc} sx={secondaryImageSx} />
        <Stack spacing={1}>
          <Typography variant="h3">{title}</Typography>
          {paragraphs.map((paragraph, index) => (
            <Typography key={index}>{paragraph}</Typography>
          ))}
        </Stack>
      </Stack>
    )
  }, [imageSrc, isMobile, paragraphs, title])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableAutoFocus
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={modalSx}
    >
      <Fade in={open} timeout={500}>
        <Box sx={wrapperSx}>
          <Box sx={contentSx}>
            <Box sx={closeIconWrapperSx} onClick={onClose}>
              <Box component="img" src="close-icon.svg" />
            </Box>
            <Box sx={innerWrapperSx}>
              {variant === 'default' && renderDefaultVariant()}
              {variant === 'secondary' && renderSecondaryVariant()}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ReadMoreDialog
