import { useMediaQuery, useTheme } from '@mui/material'

export const useResponsive = () => {
  const theme = useTheme()

  const mobileMediaQuery = theme.breakpoints.down('md')
  const mobileOrTabletMediaQuery = theme.breakpoints.down('lg')
  const desktopMediaQuery = theme.breakpoints.up('lg')

  const isMobile = useMediaQuery(mobileMediaQuery)
  const isMobileOrTablet = useMediaQuery(mobileOrTabletMediaQuery)
  const isTablet = useMediaQuery(theme.breakpoints.only('md'))
  const isTabletOrBigger = useMediaQuery(theme.breakpoints.up('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isDesktopOrBigger = useMediaQuery(theme.breakpoints.up('lg'))

  return {
    mobileMediaQuery,
    mobileOrTabletMediaQuery,
    desktopMediaQuery,
    isMobile,
    isMobileOrTablet,
    isTablet,
    isTabletOrBigger,
    isDesktop,
    isDesktopOrBigger,
  }
}
