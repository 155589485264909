import { SxProps, Theme } from '@mui/material'

export const modalSx: SxProps<Theme> = {
  outline: 'none',
}

export const wrapperSx: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
  borderRadius: '2.4rem',
  background: 'rgba(0, 0, 0, 0.85)',
  padding: { xs: '2rem', lg: '4rem' },
  width: { xs: 'calc(100vw - 7.5rem)', lg: '80rem' },
  marginLeft: { xs: '2.6rem', lg: 0 },
  maxWidth: '90vw',
}

export const contentSx: SxProps<Theme> = {
  position: 'relative',
}

export const innerWrapperSx: SxProps<Theme> = {
  position: 'relative',
  outline: 'none',
  maxHeight: '75vh',
  overflowY: 'auto',
  paddingRight: { xs: '1rem', md: 0 },
}

export const closeIconWrapperSx: SxProps<Theme> = {
  position: 'absolute',
  top: { xs: '-5.8rem', lg: '-6.4rem' },
  right: { xs: '-1.8rem', lg: '-6.4rem' },
  cursor: 'pointer',
}

export const imageSx = ({
  imageSrc,
}: {
  imageSrc: string
}): SxProps<Theme> => ({
  width: { xs: '100%', md: '34.8rem' },
  minWidth: { xs: '100%', md: '34.8rem' },
  height: '31.4rem',
  outline: 'none',
  background: `url(${imageSrc}) no-repeat center center`,
  backgroundSize: 'cover',
})

export const secondaryImageSx: SxProps<Theme> = {
  flexShrink: 0,
  width: 'fit-content',
  height: 'fit-content',
  outline: 'none',
}
