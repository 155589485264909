import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useRef, useState } from 'react'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpFromBracket,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'

import { useResponsive } from '../../hooks/useResponsive'

import ContactService from '../../services/contact'

import {
  AVAILABLE_ASSISTANCE_SERVICES_ITEMS,
  IContactForm,
} from '../../utils/contact-form'
import { ALLOWED_MIME_TYPES } from '../../utils/types'

import {
  formControlSx,
  inputLabelSx,
  loadingWrapperSx,
  thanksWrapperSx,
} from './styles'

const ContactForm = () => {
  const { isMobileOrTablet } = useResponsive()

  const [isLoading, setIsLoading] = useState(false)
  const [isDisplayingThanksMessage, setIsDisplayingThanksMessage] =
    useState(false)
  const [isServicesSelectOpen, setIsServicesSelectOpen] = useState(false)

  const rfpFileInputRef = useRef<HTMLInputElement>(null)
  const additionalFilesInputRef = useRef<HTMLInputElement>(null)

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(100, 'Max length reached')
      .required('First name is required'),
    lastName: Yup.string()
      .max(100, 'Max length reached')
      .required('First name is required'),
    email: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
    phone: Yup.string()
      .max(12, 'Max length reached')
      .required('Phone is required'),
    organization: Yup.string()
      .max(200, 'Max length reached')
      .required('Organization is required'),
    role: Yup.string()
      .max(100, 'Max length reached')
      .required('Role is required'),
    website: Yup.string().max(2048, 'Max length reached').optional(),
    services: Yup.array().min(1, 'Select at least one service'),
    currentManagement: Yup.string()
      .max(100, 'Max length reached')
      .required('Current management is required'),
    additionalInformation: Yup.string()
      .max(500, 'Max length reached')
      .optional(),
    rfpFile: Yup.mixed().optional(),
    additionalFiles: Yup.array().optional(),
  })

  const handleOnSubmit = async (values: IContactForm) => {
    try {
      setIsLoading(true)

      await ContactService.sendContactForm(values)

      setIsDisplayingThanksMessage(true)
      formik.resetForm()
    } catch (err) {
      console.error(err)
    }

    setIsLoading(false)
  }

  const handleRfpFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = event.target

    if (files) {
      const file = files[0]

      if (!ALLOWED_MIME_TYPES.includes(file.type)) {
        alert('Invalid file type')
        return
      }

      formik.setFieldValue(name, file)
    }
  }

  const handleAdditionalFilesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, files } = event.target

    if (files) {
      const filesArray = Array.from(files)

      for (const file of filesArray) {
        if (!ALLOWED_MIME_TYPES.includes(file.type)) {
          alert('Invalid file type')
          return
        }
      }

      formik.setFieldValue(name, filesArray)
    }
  }

  const formik = useFormik<IContactForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      organization: '',
      role: '',
      website: '',
      services: [],
      currentManagement: '',
      additionalInformation: '',
      rfpFile: undefined,
      additionalFiles: [],
    },
    validationSchema,
    onSubmit: handleOnSubmit,
  })

  if (isLoading) {
    return (
      <Stack sx={loadingWrapperSx}>
        <CircularProgress size={50} />
      </Stack>
    )
  }

  return (
    <Stack component="form" noValidate onSubmit={formik.handleSubmit}>
      {!isDisplayingThanksMessage ? (
        <Stack rowGap="2rem">
          <Typography variant="h3">Contact us and submit an RFP</Typography>
          <Typography>* Required</Typography>
          <Grid container rowSpacing="2rem" columnSpacing="1.6rem">
            <Grid item xs={12} md={6}>
              <TextField
                id="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                inputProps={{ maxLength: 100 }}
                variant="outlined"
                placeholder="First Name*"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                inputProps={{ maxLength: 100 }}
                variant="outlined"
                placeholder="Last Name*"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                inputProps={{ maxLength: 12 }}
                variant="outlined"
                placeholder="Phone Number*"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                placeholder="Email Address*"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="organization"
                value={formik.values.organization}
                onChange={formik.handleChange}
                error={
                  formik.touched.organization &&
                  Boolean(formik.errors.organization)
                }
                helperText={
                  formik.touched.organization && formik.errors.organization
                }
                inputProps={{ maxLength: 200 }}
                variant="outlined"
                placeholder="Association/Organization Name*"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="website"
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
                variant="outlined"
                placeholder="Website URL"
                inputProps={{ maxLength: 2048 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
                variant="outlined"
                placeholder="What is your current role with the organization?*"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={formControlSx}>
                <InputLabel
                  sx={inputLabelSx}
                  shrink={!!formik.values.services.length}
                  style={{
                    display:
                      !!formik.values.services.length || isServicesSelectOpen
                        ? 'none'
                        : 'block',
                  }}
                >
                  With what services do you need assistance?*
                </InputLabel>
                <Select
                  multiple
                  id="services-select"
                  name="services"
                  value={formik.values.services}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.services && Boolean(formik.errors.services)
                  }
                  onOpen={() => setIsServicesSelectOpen(true)}
                  onClose={() => setIsServicesSelectOpen(false)}
                  variant="outlined"
                  sx={{
                    '& #services-select': {
                      whiteSpace: 'normal',
                    },
                  }}
                >
                  {AVAILABLE_ASSISTANCE_SERVICES_ITEMS.map((service) => (
                    <MenuItem key={service.value} value={service.value}>
                      {service.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="currentManagement"
                value={formik.values.currentManagement}
                onChange={formik.handleChange}
                error={
                  formik.touched.currentManagement &&
                  Boolean(formik.errors.currentManagement)
                }
                helperText={
                  formik.touched.currentManagement &&
                  formik.errors.currentManagement
                }
                variant="outlined"
                placeholder="How is your association managed now?*"
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="additionalInformation"
                value={formik.values.additionalInformation}
                onChange={formik.handleChange}
                error={
                  formik.touched.additionalInformation &&
                  Boolean(formik.errors.additionalInformation)
                }
                helperText={
                  formik.touched.additionalInformation &&
                  formik.errors.additionalInformation
                }
                variant="outlined"
                placeholder="What other information would you like us to know?"
                inputProps={{ maxLength: 500 }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="rfpFile">
                <input
                  ref={rfpFileInputRef}
                  id="rfpFile"
                  name="rfpFile"
                  type="file"
                  accept={ALLOWED_MIME_TYPES.join(',')}
                  onChange={handleRfpFileChange}
                  style={{ display: 'none' }}
                />

                <TextField
                  id="rfpFile"
                  value={formik.values.rfpFile?.name || ''}
                  error={
                    formik.touched.rfpFile && Boolean(formik.errors.rfpFile)
                  }
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <FontAwesomeIcon icon={faArrowUpFromBracket} />
                      </InputAdornment>
                    ),
                    sx: { cursor: 'pointer' },
                  }}
                  onClick={() => {
                    rfpFileInputRef.current!.click()
                  }}
                  helperText={formik.touched.rfpFile && formik.errors.rfpFile}
                  variant="outlined"
                  placeholder="Upload your RFP"
                  fullWidth
                />
              </label>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="additionalFiles">
                <input
                  ref={additionalFilesInputRef}
                  id="additionalFiles"
                  name="additionalFiles"
                  type="file"
                  accept={ALLOWED_MIME_TYPES.join(',')}
                  multiple
                  onChange={handleAdditionalFilesChange}
                  style={{ display: 'none' }}
                />

                <TextField
                  id="additionalFiles"
                  value={
                    !!formik.values.additionalFiles?.length
                      ? formik.values
                          .additionalFiles!.map((file) => file.name)
                          .join(', ')
                      : ''
                  }
                  error={
                    formik.touched.additionalFiles &&
                    Boolean(formik.errors.additionalFiles)
                  }
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <FontAwesomeIcon icon={faArrowUpFromBracket} />
                      </InputAdornment>
                    ),
                    sx: { cursor: 'pointer' },
                  }}
                  onClick={() => {
                    additionalFilesInputRef.current!.click()
                  }}
                  helperText={
                    formik.touched.additionalFiles &&
                    formik.errors.additionalFiles
                  }
                  variant="outlined"
                  placeholder="Upload additional information"
                  fullWidth
                />
              </label>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth={isMobileOrTablet}
                type="submit"
                variant="contained"
                size="large"
                endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                disabled={!formik.dirty || formik.isSubmitting}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <Stack sx={thanksWrapperSx}>
          <Box component="img" src="thanks-contact-form.png" />
          <Typography variant="h3" textTransform="none" textAlign="center">
            Thanks for submitting your information
          </Typography>
          <Typography variant="h4" textTransform="none" textAlign="center">
            A member of our team will follow up on your request as soon as
            possible.
          </Typography>
          <Button
            variant="contained"
            size="medium"
            onClick={() => setIsDisplayingThanksMessage(false)}
          >
            Complete Another Form
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

export default ContactForm
