import { SxProps, Theme } from '@mui/material'

export const thanksWrapperSx: SxProps<Theme> = {
  gap: '1.6rem',
  width: { xs: 'auto', lg: '48rem' },
  justifyContent: 'center',
  alignItems: 'center',
}

export const loadingWrapperSx: SxProps<Theme> = {
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
}

export const formControlSx: SxProps<Theme> = {
  width: '100%',
}

export const inputLabelSx: SxProps<Theme> = {
  color: 'rgba(0, 0, 0, 0.6)',
  fontWeight: 400,
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  opacity: 1,
  top: '0.3rem',
}
