import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.2rem 0',
  gap: '1.4rem',
  background: '#2B2B2B',
  paddingLeft: { xs: '5.4rem', lg: '21.2rem' },
}
