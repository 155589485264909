import { Box, Container, Stack } from '@mui/material'

import { useResponsive } from '../../hooks/useResponsive'

import Navbar from '../../components/Navbar'
import Hero from '../../components/Hero'
import OurServices from './components/OurServices'
import WhatItIs from './components/WhatItIs'
import AboutUs from './components/AboutUs'
import Contact from './components/Contact'
import Background from '../../components/Background'

import { containerSx, mainSx, parentWrapperSx, wrapperSx } from './styles'
import CopyrightFooter from '../../components/CopyrightFooter'
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton'

const Home = () => {
  const { isTabletOrBigger } = useResponsive()

  return (
    <Box component="main" sx={mainSx}>
      {isTabletOrBigger && <Background />}
      <Navbar />
      <Box sx={parentWrapperSx}>
        <Container sx={containerSx}>
          <Stack sx={wrapperSx}>
            <Hero />
            <OurServices />
            <WhatItIs />
            <AboutUs />
          </Stack>
        </Container>
      </Box>
      <Contact />
      <CopyrightFooter />
      <ScrollToTopButton />
    </Box>
  )
}

export default Home
