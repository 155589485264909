import { Box, Button, Stack, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import Logo from '../Logo'

import { HOME_ANCHOR_ID } from '../../utils/types'

import { heroBoardSx, heroSx, heroTextSx } from './styles'
import { scrollToElement } from '../../utils/scroll'
import { useResponsive } from '../../hooks/useResponsive'

const Hero = () => {
  const { isMobile } = useResponsive()

  const handleOnReadMoreButtonClick = (event: React.MouseEvent) => {
    scrollToElement(event, HOME_ANCHOR_ID.SERVICES)
  }

  const handleOnSubmitRFPButtonClick = (event: React.MouseEvent) => {
    scrollToElement(event, HOME_ANCHOR_ID.CONTACT_US)
  }

  return (
    <Box sx={heroSx} id={HOME_ANCHOR_ID.TOP}>
      <Stack sx={heroBoardSx}>
        <Logo />
        <Typography variant="caption" sx={heroTextSx}>
          Comprehensive administrative services that empower your organization.
        </Typography>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          mt={isMobile ? '1.6rem' : '3.6rem'}
        >
          <Button
            variant="outlined"
            endIcon={<FontAwesomeIcon icon={faArrowDown} />}
            onClick={handleOnReadMoreButtonClick}
          >
            Read More
          </Button>
          <Button
            variant="contained"
            endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
            onClick={handleOnSubmitRFPButtonClick}
          >
            Submit RFP
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default Hero
