import AWS, { S3 } from 'aws-sdk'

const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET_NAME
const REGION = 'us-east-1'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

const bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

const uploadFile = async ({
  referenceId,
  folder,
  file,
}: {
  referenceId: string
  folder: 'rfp' | 'additional'
  file: File
}) => {
  const params: S3.PutObjectRequest = {
    Bucket: S3_BUCKET!,
    Key: `public/${referenceId}/${folder}/${file.name}`,
    Body: file,
    ContentType: file.type,
    Metadata: {
      referenceId,
      folder,
    },
  }

  try {
    return await bucket.upload(params).promise()
  } catch (error) {
    console.error(`Error uploading file: ${error}`)
  }
}

const exports = {
  uploadFile,
}

export default exports
