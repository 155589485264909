import { SxProps, Theme } from '@mui/material'
import { CSSProperties } from 'react'

export const leftMenuBarSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2rem 1.6rem',
  height: '100vh',
  width: '5.4rem',
  position: 'fixed',
  left: 0,
  top: 0,
  background: (theme) => theme.palette.grey[900],
  zIndex: 2,
}

export const iconButtonStyles = (theme: Theme): CSSProperties => ({
  color: theme.palette.common.white,
})

export const closeIconStyles = (theme: Theme): CSSProperties => ({
  color: theme.palette.common.white,
  cursor: 'pointer',
})

export const closeIconWrapperSx: SxProps<Theme> = {
  display: 'flex',
  padding: '2.4rem',
  color: (theme) => theme.palette.common.white,
}

export const logoWrapperSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 1.6rem',
}

export const drawerPaperSx: SxProps<Theme> = {
  background: (theme) => theme.palette.grey[900],
  minWidth: '21.2rem',
}

export const drawerModalSx: SxProps<Theme> = {
  maxWidth: '21.2rem',
}
