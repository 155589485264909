import { Box } from '@mui/material'

import {
  poligon10Sx,
  poligon11Sx,
  poligon12Sx,
  poligon13Sx,
  poligon14Sx,
  poligon15Sx,
  poligon16Sx,
  poligon1Sx,
  poligon2Sx,
  poligon3Sx,
  poligon4Sx,
  poligon5Sx,
  poligon6Sx,
  poligon7Sx,
  poligon8Sx,
  poligon9Sx,
  wrapperSx,
} from './styles'
import { useResponsive } from '../../hooks/useResponsive'

const Background = () => {
  const { isTablet } = useResponsive()

  return (
    <Box sx={wrapperSx}>
      <Box component="img" src="poligons.png" sx={poligon1Sx} />
      <Box component="img" src="poligons.png" sx={poligon2Sx} />
      <Box component="img" src="poligons.png" sx={poligon3Sx} />
      <Box component="img" src="poligons.png" sx={poligon4Sx} />
      <Box component="img" src="poligons.png" sx={poligon5Sx} />
      <Box component="img" src="poligons.png" sx={poligon6Sx} />
      <Box component="img" src="poligons.png" sx={poligon7Sx} />
      <Box component="img" src="poligons.png" sx={poligon8Sx} />
      <Box component="img" src="poligons.png" sx={poligon9Sx} />
      <Box component="img" src="poligons.png" sx={poligon10Sx} />
      <Box component="img" src="poligons.png" sx={poligon11Sx} />
      <Box component="img" src="poligons.png" sx={poligon12Sx} />
      <Box component="img" src="poligons.png" sx={poligon13Sx} />
      <Box component="img" src="poligons.png" sx={poligon14Sx} />
      {isTablet && <Box component="img" src="poligons.png" sx={poligon15Sx} />}
      {isTablet && <Box component="img" src="poligons.png" sx={poligon16Sx} />}
    </Box>
  )
}

export default Background
