import { SxProps, Theme } from '@mui/material'

export const wrapperSx: SxProps<Theme> = {
  marginTop: '5.3rem',
  marginBottom: { xs: '4rem', lg: '21rem' },
}

export const innerContentSx: SxProps<Theme> = {
  padding: { xs: 0, md: '0 4rem', lg: '0 9.2rem' },
  rowGap: { xs: '4rem', md: '8rem' },
}

export const subtitleSx: SxProps<Theme> = {
  maxWidth: '52.7rem',
}
