import { SxProps, Theme, alpha } from '@mui/material'

export const heroSx: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  padding: { xs: '2.4rem', lg: '4.4rem' },
  width: '100%',
  height: { xs: 'auto', lg: '58.4rem' },
  background: 'url(hero.jpeg) no-repeat center center',
  backgroundSize: 'cover',
}

export const heroBoardSx: SxProps<Theme> = {
  padding: '2.4rem',
  width: '41.3rem',
  background: (theme) => alpha(theme.palette.common.white, 0.9),
  borderRadius: '1.6rem',
  boxShadow: '0rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25)',
}

export const heroTextSx: SxProps<Theme> = {
  color: (theme) => theme.palette.text.secondary,
  marginTop: '2rem',
  fontSize: { xs: '1.3rem', md: '1.6rem' },
}
