import { Stack, Typography } from '@mui/material'

import DemoCard from '../../../../components/DemoCard'
import { IDemoCardProps } from '../../../../components/DemoCard/DemoCard'

import { HOME_ANCHOR_ID } from '../../../../utils/types'

import { innerContentSx, subtitleSx, wrapperSx } from './styles'
import { useResponsive } from '../../../../hooks/useResponsive'

const OUR_SERVICES_ITEMS: IDemoCardProps[] = [
  {
    title: 'Executive Management Services',
    text: "Unlock your organization's full potential with Executive Management Services, delivering tailored solutions and visionary guidance to drive innovation and maximize efficiency.",
    imageSrc: 'executive-management-services.png',
  },
  {
    title: 'Administrative and Membership Services',
    text: 'Streamlining operations and fostering a thriving community for organizations, ensuring seamless administration and enhanced member engagement.',
    imageSrc: 'administrative-and-membership-services.png',
  },
  {
    title: 'Bookkeeping and Accounting Services',
    text: 'Elevate your financial management with our professional Bookkeeping and Accounting Services, delivering precision, transparency, and strategic insights to drive your business forward.',
    imageSrc: 'bookkeeping-and-accounting-services.png',
  },
  {
    title: 'Meeting Planning and Contract Negotiation',
    text: 'Unlock the potential of your meetings and contracts with our expert Meeting Planning and Contract Negotiation services, ensuring meticulous coordination and favorable terms that drive your business objectives.',
    imageSrc: 'meeting-planning-and-contract-negotiation.png',
  },
  {
    title: 'Web Design and Management',
    text: 'Elevate your digital presence with our professional Web Design and Management services, combining artistic flair with technical expertise to create engaging websites and optimize user experience.',
    imageSrc: 'web-design-and-management.png',
  },
  {
    title: 'Communications',
    text: 'Transform your communication strategy with our expert Communications services, leveraging compelling narratives and targeted messaging to effectively connect with your audience and achieve your business goals.',
    imageSrc: 'communications.png',
  },
]

const OurServices = () => {
  const { isMobile } = useResponsive()

  return (
    <Stack sx={wrapperSx} id={HOME_ANCHOR_ID.SERVICES}>
      <Typography variant="h1">Our Services</Typography>
      <Stack sx={innerContentSx}>
        <Typography variant="body2" sx={subtitleSx}>
          Welcome to Kapsher Consulting, where we bring expertise, innovation,
          and tailored solutions to help you overcome business challenges and
          achieve success.
        </Typography>
        {OUR_SERVICES_ITEMS.map(({ title, text, imageSrc }, i) => {
          const variant = i % 2 === 0 || isMobile ? 'right' : 'left'
          return (
            <DemoCard
              title={title}
              text={text}
              imageSrc={imageSrc}
              variant={variant}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

export default OurServices
